const announcementModalContainerSelector = "#announcement-modal-container";
const announcementModalSelector = "#announcement-modal";

const loadModal = container => {
  const modalContentUrl = container.dataset.contentUrl;

  fetch(modalContentUrl)
    .then(response => response.text())
    .then(html => {
      container.innerHTML = html;
      $(announcementModalSelector).modal("show");
    })
    .catch(error => console.error("Error loading announcement modal:", error));
};

$(document).ready(() => {
  const container = document.querySelector(announcementModalContainerSelector);
  if (container && container.innerHTML === "") loadModal(container);
});
