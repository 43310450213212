$(document).ready(function() {
  $(".tooltip-auto").tooltip({ container: "body" });
  $(".tooltip-top").tooltip({ placement: "top", container: "body" });
  $(".tooltip-bottom").tooltip({ placement: "bottom", container: "body" });
  $(".tooltip-left").tooltip({ placement: "left", container: "body" });
  $(".tooltip-right").tooltip({ placement: "right", container: "body" });

  $('.enable-popover[data-toggle="popover"]').popover();
  $('.enable-html-popover[data-toggle="popover"]').popover({
    html: true
  });

  $(".date-field").datepicker({
    changeMonth: true,
    changeYear: true,
    yearRange: "-95:+10"
  });
});
