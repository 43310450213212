const toggleVisibility = function(element, action, options) {
  const $element = $(element);
  const field = $element.find("input, select");
  var options = options || {};
  const className = options.className || "d-none";

  if (action === "show") {
    if ($element.hasClass(className)) {
      $element.removeClass(className);
    }
    if (!options.skipDisabling) {
      field.prop("disabled", false);
    }
  } else if (action === "hide") {
    if (!$element.hasClass(className)) {
      $element.addClass(className);
    }
    if (!options.skipDisabling) {
      field.prop("disabled", true);
    }
  }
};

const toggleSearchFieldVisibility = function(selectField) {
  const row = selectField.parents(".form-row");
  const currentVisibleSearchField = row
    .find(".searchable-field")
    .filter(function() {
      return !$(this).hasClass("d-none");
    });
  const searchableInput = row.find(".searchable-input");
  const searchableSelect = row.find(".searchable-select");

  // checks if the option selected refers to an enum
  if (selectField.find("option:selected").hasClass("filterable-enum")) {
    // go through each select and shows to correct select and hides the other selects
    searchableSelect.each(function() {
      if ($(this).attr("data-select") === selectField.val()) {
        toggleVisibility(this, "show");
      } else {
        toggleVisibility(this, "hide");
      }
    });
    // hides the search field
    toggleVisibility(searchableInput, "hide");
  } else if (selectField.find("option:selected").hasClass("filterable-scope")) {
    // hides the search field and select fields
    toggleVisibility(searchableInput, "hide", { skipDisabling: true });
    if (currentVisibleSearchField.hasClass("searchable-select")) {
      searchableInput.find("input").val("");
    }
    searchableSelect.each(function() {
      return toggleVisibility(this, "hide");
    });
  } else {
    // shows the search field and clears it if the current search field is a select
    toggleVisibility(searchableInput, "show");
    if (currentVisibleSearchField.hasClass("searchable-select")) {
      searchableInput.find("input").val("");
    }
    searchableSelect.each(function() {
      return toggleVisibility(this, "hide");
    });
  }
};

const toggleSearchConjunctionVisibility = function() {
  const conjunction = $(".searchable-conjunction");

  toggleVisibility(conjunction, "show");
  toggleVisibility(conjunction.last(), "hide");
  if (conjunction.length === 1) {
    toggleVisibility(conjunction.first(), "hide");
  }
};

$(document).on("click", ".searchable-add-filter", function(event) {
  event.preventDefault();

  const form = $("#search-form");
  const model = form.attr("data-model");
  const button = $(this);

  $.get("/admin/searches/new", { model }, function(html) {
    button.parents(".form-row").after(html);
    $(".searchable-filter select").each(function() {
      toggleSearchFieldVisibility($(this));
    });
    toggleSearchConjunctionVisibility();
  });
});

$(document).on("click", ".searchable-remove-filter", function(event) {
  event.preventDefault();
  $(this)
    .parents(".form-row")
    .remove();
  $(".searchable-filter select").each(function() {
    toggleSearchFieldVisibility($(this));
  });
  toggleSearchConjunctionVisibility();
});

$(document).ready(function() {
  $(".searchable-filter select").each(function() {
    toggleSearchFieldVisibility($(this));
  });
  toggleSearchConjunctionVisibility();

  $(document).on("change", ".searchable-filter select", function() {
    toggleSearchFieldVisibility($(this));
  });
});
