function showQuestionForm(type, button) {
  const parentSelector = `.question-${type}`;
  const editQuestionSelector = `.edit-question-${type}`;
  const questionTextSelector = `.question-${type}-text`;
  const parent = $(button).parents(parentSelector);
  parent.find(questionTextSelector).addClass("hidden");
  parent.find(".question-actions").addClass("hidden");
  parent.find(editQuestionSelector).removeClass("hidden");
}

$(function() {
  $(".btn-edit-question").on("click", function(e) {
    e.preventDefault();
    showQuestionForm("body", this);
  });

  $(".btn-edit-result").on("click", function(e) {
    e.preventDefault();
    showQuestionForm("result", this);
  });

  $(".question-result-select").on("change", function() {
    const form = $(this).parents(".edit-result-form");
    const resultType = $(this).val();
    if (resultType === "QuestionnaireQuestion") {
      form.find(".result-question").removeClass("hidden");
      form.find(".result-question input").prop("disabled", false);
      form.find(".result-form").addClass("hidden");
      form.find(".result-form select").prop("disabled", true);
    } else if (resultType === "Form") {
      form.find(".result-form").removeClass("hidden");
      form.find(".result-form select").prop("disabled", false);
      form.find(".result-question").addClass("hidden");
      form.find(".result-question input").prop("disabled", true);
    } else {
      form.find(".result-form").addClass("hidden");
      form.find(".result-form select").prop("disabled", true);
      form.find(".result-question").addClass("hidden");
      form.find(".result-question input").prop("disabled", true);
    }
  });
});
