const addNewFaqGroup = function() {
  const faqGroupHtml = `
        <div class="card mb-3">
            <div class="card-header">
                FAQ Section
                <button class="btn btn-danger btn-sm float-right remove-faq-group" type="button">Remove</button>
            </div>
            <div class="card-body">
                <label for="branch_faqs__title">Section Title</label>
                <input type="text" name="branch[faqs][][title]" id="branch_faqs__title" value="" class="form-control">
                <div class="form-row mt-2">
                    <div class="col-md-5">
                        <label for="branch_faqs__questions__question">Question</label>
                        <textarea name="branch[faqs][][questions][][question]" id="branch_faqs__questions__question" class="form-control"></textarea>
                    </div>
                    <div class="col-md-5">
                        <label for="branch_faqs__questions__answer">Answer</label>
                        <textarea name="branch[faqs][][questions][][answer]" id="branch_faqs__questions__answer" class="form-control"></textarea>
                    </div>
                    <div class="col-md-2">
                        <button class="btn btn-danger btn-sm mt-4 remove-faq" type="button">Remove</button>
                    </div>
                </div>
                <button class="btn btn-primary mt-4 add-faq" type="button">Add FAQ</button>
            </div>
        </div>
    `;
  $(".faq-question-groups").append(faqGroupHtml);
};

const addNewFaq = function(button) {
  const faqHtml = `
        <div class="form-row mt-2">
            <div class="col-md-5">
                <label for="branch_faqs__questions__question">Question</label>
                <textarea name="branch[faqs][][questions][][question]" id="branch_faqs__questions__question" class="form-control"></textarea>
            </div>
            <div class="col-md-5">
                <label for="branch_faqs__questions__answer">Answer</label>
                <textarea name="branch[faqs][][questions][][answer]" id="branch_faqs__questions__answer" class="form-control"></textarea>
            </div>
            <div class="col-md-2">
                <button class="btn btn-danger btn-sm mt-4 remove-faq" type="button">Remove</button>
            </div>
        </div>
    `;
  $(button).before(faqHtml);
};

var refreshOnClickHandlers = function() {
  // Identify all of our related buttons
  addNewFaqGroupButton = $(".add-faq-group");
  removeFaqGroupButton = $(".remove-faq-group");
  addNewFaqButton = $(".add-faq");
  removeFaqButton = $(".remove-faq");

  // Unbind any existing click event handlers
  addNewFaqGroupButton.unbind("click");
  removeFaqGroupButton.unbind("click");
  addNewFaqButton.unbind("click");
  removeFaqButton.unbind("click");

  // Bind click event handlers to old and new buttons
  addNewFaqGroupButton.on("click", function() {
    addNewFaqGroup();
    refreshOnClickHandlers();
  });
  removeFaqGroupButton.on("click", function() {
    const faqGroup = $(this).parents(".card")[0];
    faqGroup.remove();
  });
  addNewFaqButton.on("click", function() {
    addNewFaq(this);
    refreshOnClickHandlers();
  });
  removeFaqButton.on("click", function() {
    const faqRow = $(this).parents(".form-row")[0];
    faqRow.remove();
  });
};

$(document).ready(function() {
  refreshOnClickHandlers();
});
