// TODO: Add validations

let cardHeaderPopover;
let sectionsList;
let sectionPositions;

cardHeaderPopover = $(".card-header [data-toggle='popover']");
sectionsList = $("#sections-list");
sectionPositions = $(".section-position");

// Enable section description popover

cardHeaderPopover.popover({
  html: true
});

// Enable section card reordering
// Update hidden :position fields

sectionsList
  .sortable({
    placeholder: "card-placeholder",
    handle: ".anchor",
    axis: "y",
    stop(event, ui) {
      ui.item.removeAttr("style");
    }
  })
  .on("sortupdate cocoon:after-insert cocoon:after-remove", function() {
    updateSectionPositionValues();
  })
  .disableSelection();

function updateSectionPositionValues() {
  $(".section-position").each(function(index, element) {
    $(element).val(index);
  });
}
