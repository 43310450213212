import DragAndDrop from "./drag_and_drop";

$("#instructions-modal.auto-show").modal("show");

const explainOnTruthyRadio = $(
  '[data-comment-condition="truthy_answer"] input[type=radio]'
);
const explainOnFalsyRadio = $(
  '[data-comment-condition="falsy_answer"] input[type=radio]'
);

explainOnTruthyRadio.on("change", function() {
  showExplanationFields(this, "0");
});
explainOnFalsyRadio.on("change", function() {
  showExplanationFields(this, "1");
});

function showExplanationFields(radioButton, conditionalValue) {
  let commentFields;

  commentFields = $(radioButton)
    .parents(".form-value-fields")
    .children(".comment-field");

  if ($(radioButton).val() === conditionalValue) {
    commentFields.show();
  } else {
    commentFields.hide();
  }
}

const signatureComplete = $("#signature-complete");
signatureComplete.on("click", function() {
  showCompletionOptions(this);
});

function showCompletionOptions(evt) {
  const paymentSelector = "#payment";
  const paymentInformationSelector = "#payment-information";
  const formSubmissionSelector = "#form-submit";

  if ($(paymentInformationSelector).length === 0) {
    // in the case that payment div is not present,
    // we need to enable submission and show the submit button.
    // We check payment information now to show a basic status if the form has already been paid.
    allowSubmission();
    $(paymentSelector).collapse("show");
    $(formSubmissionSelector).collapse("show");
  } else {
    $(paymentSelector).collapse("show");
    $(formSubmissionSelector).collapse("show");
  }
}

const stripe = Stripe(LEOSA_CONFIG.STRIPE_PUBLISHABLE_KEY);
const elements = stripe.elements();
let card;
let showErrors;
let clearErrors;

const createCard = function() {
  const cardSelector = "#card-element";
  if ($(cardSelector).length === 0) {
    return false;
  }

  const cardOptions = {
    style: {
      base: {
        // Add your base input styles here. For example:
        fontFamily:
          '"Josefin Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
        fontSize: "16px",
        color: "#413351"
      }
    }
  };

  // Create an instance of the card Element.
  card = elements.create("card", cardOptions);
  // Add an instance of the card Element into the `card-element` <div>.
  card.mount(cardSelector);

  card.addEventListener("change", function(event) {
    if (event.error) {
      showErrors(event.error.message);
      preventSubmission();
    } else {
      clearErrors();
      allowSubmission();
    }
  });
};

const createApplePay = function() {
  const applePaySelector = "#payment-request-button";
  if ($(applePaySelector).length === 0) {
    return false;
  }

  const applePayButton = $(applePaySelector)[0];
  console.log(applePayButton);
  const stripePrice = applePayButton.getAttribute("data-price") * 100;
  const paymentRequest = stripe.paymentRequest({
    country: "US",
    currency: "usd",
    total: {
      label: "Submission Cost",
      amount: stripePrice
    },
    requestPayerName: true,
    requestPayerEmail: true
  });

  const prButton = elements.create("paymentRequestButton", {
    paymentRequest,
    style: {
      paymentRequestButton: {
        type: "default", // default: 'default'
        theme: "dark", // default: 'dark'
        height: "40px" // default: '40px', the width is always '100%'
      }
    }
  });

  (async () => {
    // Check the availability of the Payment Request API first.
    const result = await paymentRequest.canMakePayment();
    if (result) {
      prButton.mount(applePaySelector);
    } else {
      applePayButton.style.display = "none";
    }
  })();

  paymentRequest.on("token", async event => {
    const stripe_token = event.token.id;
    submitApplePay(stripe_token);
  });

  // DISABLE IF NOT SUBMITTABLE
  // TODO - Refactor this to DRY out.
  function submitApplePay(stripe_token) {
    const signatureValue = document.getElementById("signature").value;
    const $form = $("#confirm_submission");

    const tokenInput = document.createElement("input");
    tokenInput.setAttribute("type", "hidden");
    tokenInput.setAttribute("name", "submission[stripe_token]");
    tokenInput.setAttribute("value", stripe_token);
    $form.append(tokenInput);

    const signatureInput = document.createElement("input");
    signatureInput.setAttribute("type", "hidden");
    signatureInput.setAttribute("name", "submission[signature]");
    signatureInput.setAttribute("value", signatureValue);
    $form.append(signatureInput);

    const paymentTypeInput = document.createElement("input");
    paymentTypeInput.setAttribute("type", "hidden");
    paymentTypeInput.setAttribute("name", "submission[payment_type]");
    paymentTypeInput.setAttribute("value", "stripe_apple_pay");
    $form.append(paymentTypeInput);

    $form.submit();
    return true;
  }
};

function submitForPayment() {
  const signatureValue = document.getElementById("signature").value;
  const $form = $("#confirm_submission");
  const $button = $(this);

  $button.prop("disabled", true);

  const signatureInput = document.createElement("input");
  signatureInput.setAttribute("type", "hidden");
  signatureInput.setAttribute("name", "submission[signature]");
  signatureInput.setAttribute("value", signatureValue);
  $form.append(signatureInput);

  if ($(".pay-by-mail").prop("checked")) {
    const paymentTypeInput = document.createElement("input");
    paymentTypeInput.setAttribute("type", "hidden");
    paymentTypeInput.setAttribute("name", "submission[payment_type]");
    paymentTypeInput.setAttribute("value", "check");
    $form.append(paymentTypeInput);
    $form.submit();
    return true;
  }
  stripe.createToken(card).then(function(result) {
    $button.prop("disabled", false);
    if (result.error) {
      showErrors(result.error.message);
      return false;
    }
    clearErrors();

    const tokenInput = document.createElement("input");
    tokenInput.setAttribute("type", "hidden");
    tokenInput.setAttribute("name", "submission[stripe_token]");
    tokenInput.setAttribute("value", result.token.id);
    $form.append(tokenInput);

    const paymentTypeInput = document.createElement("input");
    paymentTypeInput.setAttribute("type", "hidden");
    paymentTypeInput.setAttribute("name", "submission[payment_type]");
    paymentTypeInput.setAttribute("value", "stripe_credit_card");
    $form.append(paymentTypeInput);

    $form.submit();
    return true;
  });
}

function submitNormal() {
  const signatureValue = document.getElementById("signature").value;
  const $button = $(this);
  const $form = $("#confirm_submission");
  $button.prop("disabled", true);

  const signatureInput = document.createElement("input");
  signatureInput.setAttribute("type", "hidden");
  signatureInput.setAttribute("name", "submission[signature]");
  signatureInput.setAttribute("value", signatureValue);
  $form.append(signatureInput);

  $form.submit();
  return true;
}

function paymentIsRequired() {
  const paymentSelector = "#payment-information";
  return $(paymentSelector).length !== 0;
}

function allowSubmission() {
  $("#submit-button").attr("disabled", false);
}

function preventSubmission() {
  $("#submit-button").attr("disabled", true);
}

// Handle click of submission button.
$("#submit-button").click(function(event) {
  event.preventDefault();

  if (paymentIsRequired()) {
    submitForPayment(event);
  } else {
    submitNormal(event);
  }
});

// Handle checking/unchecking of pay-by-mail option.
$(".pay-by-mail").change(function() {
  if ($(this).prop("checked")) {
    allowSubmission();
    $("#pay-online").collapse("hide");
    $("#amount").text($("#card-mailing-instructions").attr("data-price"));
    $("#card-mailing-instructions").collapse("show");
  } else {
    preventSubmission();
    $("#pay-online").collapse("show");
    $("#amount").text($("#pay-online").attr("data-price"));
    $("#card-mailing-instructions").collapse("hide");
  }
});

// Enable modal dismiss after stuff typed into signature field.
$("#signature").on("input", function() {
  $("#signature-complete").prop("disabled", false);
});

// Show Stripe errors for card field.
showErrors = function(message) {
  $("#waiver-modal").modal("hide");
  const errorElement = document.getElementById("card-errors");
  errorElement.textContent = message;
  return true;
};

// Clear Stripe errors for card field.
clearErrors = function() {
  const errorElement = document.getElementById("card-errors");
  errorElement.textContent = "";
  return true;
};

$(document).ready(function() {
  const dragAndDrop = new DragAndDrop(".drop-area", {
    uploadUrl: "/submissions/upload",
    syncSubmission: true
  });
  dragAndDrop.initialize();
  dragAndDrop.bindEvents();

  // Add in hidden fields for the button and then submit
  $(document).on("click", ".section-actions", function(event) {
    event.preventDefault();
    const button = $(this);
    const form = $(`#${button.attr("form")}`);
    form.append(
      `<input type="hidden" name="${button.attr("name")}" value="${button.attr(
        "value"
      )}">`
    );
    form.submit();
  });

  // Prevent enter from submitting on overview page.
  jQuery.each($("#confirm_submission").find("input"), function() {
    $(this).bind("keypress keydown keyup", function(e) {
      if (e.keyCode == 13) {
        e.preventDefault();
      }
    });
  });

  // Prevent submission by default unless pay-by-mail previously checked
  if (!$(".pay-by-mail").prop("checked")) {
    preventSubmission();
  }

  createCard();
  createApplePay();
});
