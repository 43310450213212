// Pre-process images for browser compatability
// * Converts HEIC images to 100% quality PNGs

import heic2any from "heic2any";

const heic2png = async heicFile => {
  const blob = await heic2any({
    blob: heicFile,
    toType: "image/png",
    quality: 1
  });

  return new File([blob], heicFile.name, { type: blob.type });
};

const convert = imageFile => {
  switch (imageFile.type) {
    case "image/heif":
      return heic2png(imageFile);
    default:
      return imageFile;
  }
};

const preprocessImage = async imageFile => {
  return convert(imageFile);
};

export default preprocessImage;
