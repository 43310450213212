const loadSalesStatistics = function() {
  if ($("#sales-chart").length === 0) {
    return false;
  }

  const base_url = "/admin/statistics";
  const query = $("#sales-form").serialize();
  const url = `${base_url}?${query}`;

  $.getJSON(url, function(data) {
    $(".online-total").text(data.grand_total.online_total_sales);
    $(".check-total").text(data.grand_total.check_total_sales);
    $(".grand-total").text(data.grand_total.total_sales);

    if (data.stats.length === 0) {
      return;
    }

    const context = document.getElementById("sales-chart").getContext("2d");
    const backgroundColors = [
      "rgba(255, 99, 132, 0.2)",
      "rgba(54, 162, 235, 0.2)",
      "rgba(255, 206, 86, 0.2)",
      "rgba(75, 192, 192, 0.2)",
      "rgba(153, 102, 255, 0.2)",
      "rgba(255, 159, 64, 0.2)"
    ];
    const borderColors = [
      "rgba(255,99,132,1)",
      "rgba(54, 162, 235, 1)",
      "rgba(255, 206, 86, 1)",
      "rgba(75, 192, 192, 1)",
      "rgba(153, 102, 255, 1)",
      "rgba(255, 159, 64, 1)"
    ];
    const labels = data.stats.map(d => d.name);
    const onlineDatasets = [
      {
        label: "Online Sales",
        data: data.stats.map(d => d.online_total_sales),
        backgroundColor: backgroundColors[0],
        borderColor: borderColors[0],
        borderWidth: 1
      }
    ];
    const checkDatasets = [
      {
        label: "By Check Sales",
        data: data.stats.map(d => d.check_total_sales),
        backgroundColor: backgroundColors[1],
        borderColor: borderColors[1],
        borderWidth: 1
      }
    ];
    const totalDatasets = [
      {
        label: "Total Sales",
        data: data.stats.map(d => d.total_sales),
        backgroundColor: backgroundColors[2],
        borderColor: borderColors[2],
        borderWidth: 1
      }
    ];
    const chart = new Chart(context, {
      type: "horizontalBar",
      data: {
        labels,
        datasets: onlineDatasets.concat(checkDatasets).concat(totalDatasets)
      },
      options: {
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      }
    });
  });
};

$(document).ready(function() {
  loadSalesStatistics();
});
