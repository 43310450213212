// TODO: Add validations

let termsCheckbox;
let acceptTermsButton;
let submitButton;

termsCheckbox = $("#accept-registration-terms-checkbox");
acceptTermsButton = $(".accept-registration-terms-button");
submitButton = $("#user-registration-submit");

$(document).ready(function() {
  resetCheckTermsButton();
});

// Enable/disable submit button when terms checkbox is toggled

termsCheckbox.on("change", resetSubmitButton);

// Check terms checkbox when user accepts terms from modal

acceptTermsButton.on("click", checkTermsCheckbox);

function resetSubmitButton() {
  if (this.checked === true) {
    enableSubmitButton();
  } else {
    disableSubmitButton();
  }
}

function resetCheckTermsButton() {
  termsCheckbox.prop("checked", false);
}

function enableSubmitButton() {
  submitButton.prop("disabled", false);
}

function disableSubmitButton() {
  submitButton.prop("disabled", true);
}

function checkTermsCheckbox() {
  termsCheckbox.prop("checked", true);
  enableSubmitButton();
}
