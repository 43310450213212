let formFieldsList;

formFieldsList = $("#form-fields-list");

$("#form-fields").on("cocoon:after-insert", function(event, insertedItem) {
  const modalLink = $(insertedItem).find(".launch-edit-modal-btn");
  $(modalLink).on("click", function() {
    launchEditModal(this);
  });
  launchEditModal(modalLink);
});

formFieldsList
  .sortable({
    handle: ".anchor",
    axis: "y",
    stop(event, ui) {
      ui.item.removeAttr("style");
    }
  })
  .on("sortupdate cocoon:after-insert cocoon:after-remove", function() {
    updateSectionPositionValues();
  })
  .disableSelection();

$(".launch-edit-modal-btn").on("click", function() {
  launchEditModal(this);
});

function launchEditModal(modalLink) {
  let modal;
  modal = $(modalLink)
    .parents(".nested-fields")
    .find(".modal");
  $(modal).modal("show");
}

function updateSectionPositionValues() {
  $(".section-position").each(function(index, element) {
    $(element).val(index);
  });
}
